/*global $, jQuery, alert, console*/

    "use strict";
    var isMobile = {
        Android: function() {
            return navigator.userAgent.match(/Android/i);
        },
        BlackBerry: function() {
            return navigator.userAgent.match(/BlackBerry/i);
        },
        iOS: function() {
            return navigator.userAgent.match(/iPhone|iPad|iPod/i);
        },
        Opera: function() {
            return navigator.userAgent.match(/Opera Mini/i);
        },
        Windows: function() {
            return navigator.userAgent.match(/IEMobile/i);
        },
        any: function() {
            return (isMobile.Android() || isMobile.BlackBerry() || isMobile.iOS() || isMobile.Opera() || isMobile.Windows() );
        }
    };

    var isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);


    $(document).ready(function () {
        controlTopMenu();
        // copyOnClipboard();

        $('.sidenav').sidenav();
        $('.modal').modal();
        // $('.tooltipped').tooltip();
        $('.collapsible').collapsible();
        // $('.dropdown-link_material').dropdown();
        $('.tabs').tabs();
        $('.lazy').Lazy();

        // function copyOnClipboard() {
        //     $('.copy-block').on('click', function() {
        //         var copyText = $(this).prev('a').text();
        //         navigator.clipboard.writeText(copyText);
        //         var element = $(this);
        //         element.addClass('active');
        //         element.addClass('active-copy');
        //         setTimeout(function(){ element.removeClass('active'); }, 200);
        //         setTimeout(function(){ element.removeClass('active-copy'); }, 1000);
        //     })
        // }

        $('.modal-trigger').on('click', function (e) {
            e.preventDefault();
        })

        $('.modal-close').on('click', function (e) {
            e.preventDefault();
        })

        $('.sidenav-cross').on('click', function (e) {
            e.preventDefault();
        })


        modalSignController();
        function modalSignController() {
            $('.modal-choose-signup__selector').on('click', function () {
                var curSelector = $(this).attr('data-name')
                $(this).closest('.modal-choose-signup__selectors-wrap').find('.modal-choose-signup__selector').each(function () {
                    $(this).removeClass('active')
                    if ($(this).attr('data-name') == curSelector) {
                        $(this).addClass('active')
                    }
                })

                $(this).closest('.modal-content').find('.btn-custom').each(function () {
                    $(this).removeClass('active')
                    if ($(this).attr('data-name') == curSelector) {
                        $(this).addClass('active')
                    }
                })

            })


            $('.modal-open-signup').on('click', function (e) {
                e.preventDefault()

                $('#modalChooseSignup').modal('close')

                setTimeout(function () {
                    $('#modalChooseLogin').modal('open')
                },600)
            })

            $('.modal-open-login').on('click', function (e) {
                e.preventDefault()

                $('#modalChooseLogin').modal('close')

                setTimeout(function () {
                    $('#modalChooseSignup').modal('open')
                },600)
            })


        }



        detectSeenElements();

        // $('.play-btn').on('click', function () {
        //     // $(this).closest('.s-video__video-wrap').addClass('active').find('.s-video__video').get(0).play();
        //
        //     $(this).closest('.s-video__video-wrap').addClass('active').find(".s-video__video-youtube")[0].src += "&autoplay=1";
        // });


        $('.modal__cross').on('click', function (e) {
            e.preventDefault()
        })
        $('#signUpBtnModal').on('click', function (e) {
            e.preventDefault()

            $('#modalLogin').modal('close')

            setTimeout(function () {
                $('#modalSignUp').modal('open')
            },600)
        })

        $('#resetBtnModal').on('click', function (e) {
            e.preventDefault()

            $('#modalLogin').modal('close')

            setTimeout(function () {
                $('#modalReset').modal('open')
            },600)
        })

        // $('#termsBtnModal').on('click', function (e) {
        //     e.preventDefault()
        //
        //     $('#modalSignUp').modal('close')
        //
        //     setTimeout(function () {
        //         $('#termsModal2').modal('open')
        //     },600)
        // })

        $('.animated-scroll').on('click', function (e) {
            e.preventDefault();
            var linkHref = $(this).attr('href');
            $('html, body').animate({
                scrollTop: $(linkHref).offset().top - 120
            }, 500);
        });



        controlActiveMenu();
        function controlActiveMenu() {
            $('.top-menu-link').each(function () {
                var curHref = $(this).attr('href');
                $(this).removeClass('active');
                if (window.location.pathname.indexOf(curHref) != -1) {
                    $(this).addClass('active')
                }
            })
        }


        function controlTopMenu() {
            var scrollHeight = $(window).scrollTop();

            if(scrollHeight  > 0) {
                $('.top-menu').addClass('not-top');
            } else {
                $('.top-menu').removeClass('not-top');
            }
            $(window).scroll(function() {
                scrollHeight = $(window).scrollTop();

                if(scrollHeight  > 0) {
                    $('.top-menu').addClass('not-top');
                } else {
                    $('.top-menu').removeClass('not-top');
                }
            });


        }

        function detectSeenElements() {
            var sections = $('.animation-section, .animated-block');

            sections.each(function () {
                var top_of_element = $(this).offset().top + $(window).innerHeight()/2;
                var bottom_of_element = $(this).offset().top + $(this).outerHeight();
                var bottom_of_screen = $(window).scrollTop() + $(window).innerHeight();
                var top_of_screen = $(window).scrollTop();

                if ((bottom_of_screen > top_of_element) && (top_of_screen < bottom_of_element)){
                    $(this).addClass('seen')
                } else {
                    // the element is not visible, do something else
                }
            })

            $(window).scroll(function() {

                sections.each(function () {
                    var top_of_element = $(this).offset().top + $(window).innerHeight()/2;
                    var bottom_of_element = $(this).offset().top + $(this).outerHeight();
                    var bottom_of_screen = $(window).scrollTop() + $(window).innerHeight();
                    var top_of_screen = $(window).scrollTop();

                    if ((bottom_of_screen > top_of_element) && (top_of_screen < bottom_of_element)){
                        $(this).addClass('seen')
                    } else {
                        // the element is not visible, do something else
                    }
                })
            });
        }



        $('.s-news__slider').slick({
            arrows: true,
            variableWidth: true,
            dots: false,
            slidesToShow: 1,
            slidesToScroll: 1,
            infinite: false,
            speed: 1000,
            prevArrow: '<div class="prev-btn"><img class="image_svg" src="/img/arrow-left.svg"></div>',
            nextArrow: '<div class="next-btn"><img class="image_svg" src="/img/arrow-right.svg"></div>',
            responsive: [
                {
                    breakpoint: 700,
                    settings: {
                        arrows: false
                    }
                }
            ]
        });
        $('.s-slider__slider .slick-slide').on('click', function () {
            var slideIndex = $(this).attr('data-slick-index')
            $('.s-slider__slider').slick('slickGoTo', slideIndex );
        });



        $(window).resize(function() {
            addInlineSvg();
        });



        function addInlineSvg() {
            var mySVGsToInject = document.querySelectorAll('img.image_svg');
            SVGInjector(mySVGsToInject);
        }
        addInlineSvg();



    });
    gdprCookieNotice({
        locale: 'en', //This is the default value
        timeout: 500, //Time until the cookie bar appears
        expiration: 60, //This is the default value, in days
        domain: 'tycoon.partners', //If you run the same cookie notice on all subdomains, define the main domain starting with a .
        implicit: false, //Accept cookies on page scroll automatically
        statement: '#', //Link to your cookie statement page
        performance: ['JSESSIONID', '_hjIncludedInSample', 'nQ_visitId', 'nQ_cookieId', 'locale', 'lang', '__atuvc', '__hstc', '__hssrc', '__hssc', 'hsfirstvisit', 'hubspotutk'], //Cookies in the performance category.
        analytics: ['ga', '_ga', '_gid', '_gat', 'collect', '_ga_user_id', '__utmz', '__utma', 'gwcc'], //Cookies in the analytics category.
        marketing: ['SSID', 'IDE', 'test_cookie', 'fr', 'tr', '1P_JAR', 'id', 'NID', 'APISID', 'SAPISID', 'guest_id', 'personalization_id', 'bcookie', 'bscookie', 'lidc', 'MUID', 'MUIDB', 'GPS'] //Cookies in the marketing category.
    });

